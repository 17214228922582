/* Importación de Tailwind CSS y fuentes de Google */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Definición de estilos base */
@layer base {
  html {
    font-family: 'Raleway', sans-serif; /* Definición de la fuente predeterminada */
  }
}

/* Estilos para secciones de altura completa */
/* Establecer altura completa para secciones */
.h-screen {
  height: 100vh; /* Establecer la altura al 100% del alto del viewport */
}

/* Ajustar secciones individuales si es necesario */
/* Por ejemplo, sección de Contacto */
.contacto-section {
  min-height: 100vh; /* Asegurar que la sección de Contacto ocupe todo el viewport */
}

/* Estilos para el fondo del formulario */
.form-container {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra suave */
}

/* Estilos para pantallas más pequeñas (por ejemplo, hasta 768px) */
@media (max-width: 768px) {
  .social-links {
    /* Define estilos específicos para pantallas pequeñas aquí */
    /* Por ejemplo, cambia la posición o el tamaño del componente */
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    align-items: center; /* Centra los elementos verticalmente */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-class {
    /* Estilos personalizados para pantallas medianas */
  }
}


/* Estilos para el modo oscuro */
/* Añadir estilos de modo oscuro */
.dark {
  @apply bg-gray-800 text-zinc-50; /* Aplicar estilos para el fondo oscuro y texto claro */
}

/* Estilos para la barra de navegación fija */
.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Asegura que la barra de navegación esté por encima de otros elementos */
}

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}

/* Definición de estilos base */
@layer base {
  html {
    font-family: 'Raleway', sans-serif; /* Definición de la fuente predeterminada */
  }
}

/* Estilos para secciones de altura completa */
/* Establecer altura completa para secciones */
.h-screen {
  height: 100vh; /* Establecer la altura al 100% del alto del viewport */
}

/* Ajustar secciones individuales si es necesario */
/* Por ejemplo, sección de Contacto */
.contacto-section {
  min-height: 100vh; /* Asegurar que la sección de Contacto ocupe todo el viewport */
}

/* Estilos para el fondo del formulario */
.form-container {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra suave */
}

/* Estilos para pantallas más pequeñas (por ejemplo, hasta 768px) */
@media (max-width: 768px) {
  .social-links {
    /* Define estilos específicos para pantallas pequeñas aquí */
    /* Por ejemplo, cambia la posición o el tamaño del componente */
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    align-items: center; /* Centra los elementos verticalmente */
  }
}

/* Estilos para el modo oscuro */
/* Añadir estilos de modo oscuro */
.dark {
  @apply bg-gray-800 text-zinc-50; /* Aplicar estilos para el fondo oscuro y texto claro */
}

/* Estilos para la barra de navegación fija */
.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Asegura que la barra de navegación esté por encima de otros elementos */
}

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}

